<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useSiteStore } from '~/stores/site.store';
import { LockIcon } from '../../design-system/icons/index';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { useAuthStore } from '~/stores/auth.store';
import ExternalIcon from '~/components/design-system/icons/ExternalIcon.vue';

const site = useSiteStore();
const auth = useAuthStore();
const { $t } = useNuxtApp();

const liveChatClicked = () => {
  site.toggleFeature({ feature: 'displayLiveChat' });
}

const goToWithdrawal = () => {
  site.deactivateModal();
  auth.setAccountModalPage('withdrawal');
  auth.toggleAccountModal(true);
}
</script>

<template>
  <div>
    <div class="mx-2">
      <div class="text-base-priority text-sm flex flex-col justify-center items-center flex-wrap mt-3">
        <div class="flex justify-center">
          <div class="border-gold-500 border-4 rounded-full p-2 flex">
            <LockIcon class="w-10 h-10 text-gold-500" />
          </div>
        </div>
          <p class="mt-4 line-height-3 mb-2 text-center text-sm">
            {{ $t('account-lock-restricted') }}
          </p>
          <p 
            class="text-center mb-4 line-height-3 text-sm"
            v-html="$t('only-permitted-access-withdrawal')" 
          >
          </p>
      </div>

      <div
        class="flex justify-between bg-light-200 dark:bg-dark-800 rounded-md mb-4 pl-3 cursor-pointer py-1"
        @click="liveChatClicked()"
      >
        <div class="flex items-center mt-0.5 mb-0.5 text-base-priority">
          <ExternalIcon class="w-6 mt-1 mr-3" icon-name="chat-support" />
          <p class="text-sm my-0 font-bold mr-1 line-height-1">{{ $t('chat') }}</p>
          <p class="my-0 text-sm line-height-1">{{ $t('to-live-support') }}</p>
        </div>
        <ChevronRightIcon
          class="w-4 dark:text-light-50 text-dark-700 mr-2 stroke-2 stroke-current"
        />
      </div>
    </div>
    <div class="bg-base dark:bg-layer-1 w-full px-2 py-1">
      <Button
        class="w-full my-2 justify-center"
        type="primary"
        rounded="sm"
        @click="goToWithdrawal()"
      >
        {{ $t('go-to-withdrawal-page') }}
      </Button>
    </div>
  </div>
</template>